@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Ash";
  src: local("Ash"), url("./fonts/Ash.ttf") format("truetype");
}

body {
  max-width: 100vw;
  overflow-x: hidden;
  background-color: #e01a30 !important;
}

@font-face {
  font-family: "Pixel_Gosub";
  src: local("Pixel_Gosub"), url("./fonts/Pixel_Gosub.ttf") format("truetype");
}

.ash {
  font-family: "Ash";
}

.pixel {
  font-family: "Pixel_Gosub";
}

#landing {
  background: url("./images/Background.png") repeat-x;
  width: 100vw;
  /* min-height: 110%; */
  /* height: 100%; */
  height: 100%;
  min-height: 100vh;
  background-size: contain;
  width: 5076px;
  animation: slide 60s linear infinite;
  position: absolute;
  background-repeat: repeat-x;
  overflow-x: hidden;
}

.flicker {
  animation: flick 1s linear infinite;
}

@keyframes flick {
  50% {
    opacity: 0;
  }
}

.overflow {
  overflow-x: hidden;
}

@keyframes slide {
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(-1692px, 0, 0);
  }
}

.arcade-container {
  perspective: 240px;
  transition: all 400ms;
}

.arcade-text {
  transform: rotateX(33deg) rotateY(0deg);
  transform-style: preserve-3d;
}

.arcade-container .menu-monster {
  /* display: none; */
  opacity: 0;
  transition: all 400ms;
}

.arcade-container:hover .menu-monster {
  opacity: 1;
}

.menu-container-item:hover {
  perspective: 5000px;
}



.menu-container-item {
  overflow-y: hidden;
  overflow-x: hidden;
}

#coin {
  position: relative;
  margin: 0 auto;
  /* width: 400px; */
  /* height: 400px; */
}

#coin div {
  width: 100%;
  height: 100%;
}

#coin {
  transition: -webkit-transform 1s ease-in;
  -webkit-transform-style: preserve-3d;
}
#coin div {
  position: absolute;
  -webkit-backface-visibility: hidden;
}

.side-a {
  z-index: 100;
}

.side-b {
  -webkit-transform: rotateY(-180deg);
}

.spinning {
  -webkit-animation: flipTails 3s forwards infinite;
  -moz-animation: flipTails 3s forwards infinite;
  -o-animation: flipTails 3s forwards infinite;
  animation: flipTails 3s forwards infinite;
  animation-play-state: running;
}

.heads {
  -webkit-animation: flipHeads 2s ease-out forwards;
  -moz-animation: flipHeads 2s ease-out forwards;
  -o-animation: flipHeads 2s ease-out forwards;
  animation: flipHeads 2s ease-out forwards;
  animation-play-state: paused;
}

.tails {
  -webkit-animation: flipTails 2s ease-out forwards;
  -moz-animation: flipTails 2s ease-out forwards;
  -o-animation: flipTails 2s ease-out forwards;
  animation: flipTails 2s ease-out forwards;
  animation-play-state: paused;
}

@keyframes flipHeads {
  from {
    -webkit-transform: rotateY(0);
    -moz-transform: rotateY(0);
    transform: rotateY(0);
  }
  to {
    -webkit-transform: rotateY(540deg);
    -moz-transform: rotateY(540deg);
    transform: rotateY(540deg);
  }
}

@keyframes flipTails {
  from {
    -webkit-transform: rotateY(0);
    -moz-transform: rotateY(0);
    transform: rotateY(0);
  }
  to {
    -webkit-transform: rotateY(720deg);
    -moz-transform: rotateY(720deg);
    transform: rotateY(720deg);
  }
}

.selected {
  background-color: #20253a;
  opacity: 1;
  color: #fff !important;
}

.toggle-music {
  position: absolute;
  bottom: 3%;
  right: 3%;
  z-index: 50;
}

.toggle-bonk {
  position: absolute;
  bottom: 3%;
  left: 3%;
  z-index: 50;
}

/* width */
::-webkit-scrollbar {
  width: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.6);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #fff;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #fff;
}

.menu-container {
  overflow-x: hidden;
}

.scrollTable {
  max-height: 300px;
  overflow-y: scroll;
}

.side-b.heads {
  transform: rotateY(0);
  z-index: 200 !important;
}

/* wallet shit */

/* wallet adapter overwrites */
.wallet-adapter-modal .wallet-adapter-button {
  background-color: transparent;
  border: none;
  color: #fff;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-family: "DM Sans", "Roboto", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  font-size: 16px;
  font-weight: 600;
  height: 48px;
  line-height: 48px;
  padding: 0 24px;
  border-radius: 4px;
}

.wallet-adapter-modal .wallet-adapter-button-trigger {
  background-color: #512da8;
}

.wallet-adapter-modal .wallet-adapter-button:not([disabled]):focus-visible {
  outline-color: white;
}

.wallet-adapter-modal .wallet-adapter-button:not([disabled]):hover {
  background-color: #1a1f2e;
}

.wallet-adapter-modal .wallet-adapter-button[disabled] {
  background: #404144;
  color: #999;
  cursor: not-allowed;
}

.wallet-adapter-modal .wallet-adapter-button-end-icon,
.wallet-adapter-modal .wallet-adapter-button-start-icon,
.wallet-adapter-modal .wallet-adapter-button-end-icon img,
.wallet-adapter-modal .wallet-adapter-button-start-icon img {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
}

.wallet-adapter-modal .wallet-adapter-button-end-icon {
  margin-left: 12px;
}

.wallet-adapter-button-start-icon {
  margin-right: 12px;
}

.wallet-adapter-collapse {
  width: 100%;
}

.wallet-adapter-dropdown {
  position: relative;
  display: inline-block;
}

.wallet-adapter-dropdown-list {
  position: absolute;
  z-index: 99;
  display: grid;
  grid-template-rows: 1fr;
  grid-row-gap: 10px;
  padding: 10px;
  top: 100%;
  right: 0;
  margin: 0;
  list-style: none;
  background: #2c2d30;
  border-radius: 10px;
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.6);
  opacity: 0;
  visibility: hidden;
  transition: opacity 200ms ease, transform 200ms ease, visibility 200ms;
  font-family: "DM Sans", "Roboto", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
}

.wallet-adapter-dropdown-list-active {
  opacity: 1;
  visibility: visible;
  transform: translateY(10px);
}

.wallet-adapter-dropdown-list-item {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: none;
  outline: none;
  cursor: pointer;
  white-space: nowrap;
  box-sizing: border-box;
  padding: 0 20px;
  width: 100%;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 600;
  height: 37px;
  color: #fff;
}

.wallet-adapter-dropdown-list-item:not([disabled]):hover {
  background-color: #1a1f2e;
}

.wallet-adapter-modal-collapse-button svg {
  align-self: center;
  fill: #999;
}

.wallet-adapter-modal-collapse-button.wallet-adapter-modal-collapse-button-active
  svg {
  transform: rotate(180deg);
  transition: transform ease-in 150ms;
}

.wallet-adapter-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  transition: opacity linear 150ms;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1040;
  overflow-y: auto;
}

.wallet-adapter-modal.wallet-adapter-modal-fade-in {
  opacity: 1;
}

.wallet-adapter-modal-button-close {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 18px;
  right: 18px;
  padding: 12px;
  cursor: pointer;
  background: #1a1f2e;
  border: none;
  border-radius: 50%;
}

.wallet-adapter-modal-button-close:focus-visible {
  outline-color: white;
}

.wallet-adapter-modal-button-close svg {
  fill: #777;
  transition: fill 200ms ease 0s;
}

.wallet-adapter-modal-button-close:hover svg {
  fill: #fff;
}

.wallet-adapter-modal-overlay {
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.wallet-adapter-modal-container {
  display: flex;
  margin: 3rem;
  min-height: calc(100vh - 6rem);
  /* 100vh - 2 * margin */
  align-items: center;
  justify-content: center;
}

@media (max-width: 480px) {
  .wallet-adapter-modal-container {
    margin: 1rem;
    min-height: calc(100vh - 2rem);
    /* 100vh - 2 * margin */
  }
}

.wallet-adapter-modal-wrapper {
  box-sizing: border-box;
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  z-index: 1050;
  max-width: 400px;
  border-radius: 10px;
  background: #10141f;
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.6);
  font-family: "DM Sans", "Roboto", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  flex: 1;
}

.wallet-adapter-modal-wrapper .wallet-adapter-button {
  width: 100%;
}

.wallet-adapter-modal-title {
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  margin: 0;
  padding: 64px 48px 48px 48px;
  text-align: center;
  color: #fff;
}

@media (max-width: 374px) {
  .wallet-adapter-modal-title {
    font-size: 18px;
  }
}

.wallet-adapter-modal-list {
  margin: 0 0 12px 0;
  padding: 0;
  width: 100%;
  list-style: none;
}

.wallet-adapter-modal-list .wallet-adapter-button {
  font-weight: 400;
  border-radius: 0;
  font-size: 18px;
}

.wallet-adapter-modal-list .wallet-adapter-button-end-icon,
.wallet-adapter-modal-list .wallet-adapter-button-start-icon,
.wallet-adapter-modal-list .wallet-adapter-button-end-icon img,
.wallet-adapter-modal-list .wallet-adapter-button-start-icon img {
  width: 28px;
  height: 28px;
}

.wallet-adapter-modal-list .wallet-adapter-button span {
  margin-left: auto;
  font-size: 14px;
  opacity: 0.6;
}

.wallet-adapter-modal-list-more {
  cursor: pointer;
  border: none;
  padding: 12px 24px 24px 12px;
  align-self: flex-end;
  display: flex;
  align-items: center;
  background-color: transparent;
  color: #fff;
}

.wallet-adapter-modal-list-more svg {
  transition: all 0.1s ease;
  fill: rgba(255, 255, 255, 1);
  margin-left: 0.5rem;
}

.wallet-adapter-modal-list-more-icon-rotate {
  transform: rotate(180deg);
}

.wallet-adapter-modal-middle {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 24px 24px 24px;
  box-sizing: border-box;
}

.wallet-adapter-modal-middle-button {
  display: block;
  cursor: pointer;
  margin-top: 48px;
  width: 100%;
  background-color: #512da8;
  padding: 12px;
  font-size: 18px;
  border: none;
  border-radius: 8px;
  color: #fff;
}
